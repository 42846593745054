// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'staging-na',
  production: false,
  region: 'NA',
  appUrl: 'https://posted-speed.staging.production.zonarsystems.net',

  apiBase: {
    url: 'https://posted-speed-api.production.zonarsystems.net/api/v1'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },

  geocoderApi: {
    url: 'https://api.zonarsystems.net'
  },

  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'wnKLZv21OCDg9IQrwZYy3WJgKVcY1X0i',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: '4013ba47-b4fb-4107-af28-9372becac6dd',
    defaultZonarRole: '484883ef-6b0e-4e11-a773-262ac5e927ed',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_49d9e6bb_9790_4c40_bf0f_8b31fb6e3439_f1a6c45'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '0682e73f-9602-4954-96f0-910ca3a27c62',
    clientToken: 'pubc05d7f578e8d6c9ada0fda6eeb446fe1',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'staging'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES', 'it-IT'],
    defaultLanguage: 'en-US'
  },
  here: {
    apiKeyV3: 'uJUMeJ7BI2NDrHfz9zv_1PwByr3dhqs-iIyN2pydU80'
  },
  userPreferenceApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
